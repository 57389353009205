@import '../../../../../scss/theme-bootstrap';

.facebook-login {
  @include breakpoint($large-up) {
    padding: 20px 0;
  }
  &__container {
    margin-top: 20px;
    text-align: center;
    @include breakpoint($large-up) {
      margin-top: 0;
    }
  }
  &__divider {
    margin-bottom: 25px;
    width: 100%;
  }
  &__fb-btn {
    padding-bottom: 15px;
  }
  &__terms {
    margin-top: 15px;
    padding: 0;
    text-align: $ldirection;
  }
  &__email-opt-in {
    margin-top: 13px;
    text-align: $ldirection;
    @include breakpoint($large-up) {
      float: $ldirection;
    }
  }
  &__title,
  &__info {
    display: none;
  }
  input[type='checkbox'] {
    #{$ldirection}: 88px;
    opacity: 0;
  }
}
